@charset 'utf-8';

$home-profile: true;
$home-posts: false;
$header-normal-mode-desktop: false;
$header-normal-mode-mobile: false;

@import "_variables";@import "_core/normalize";
@import "_core/base";
@import "_core/layout";

@import "_page/index";

@import "_partial/header";
@import "_partial/footer";
@import "_partial/pagination";

@import "_core/media";